import React, {useEffect, useState} from 'react';
import CenteredLayout from "../../layouts/CenteredLayout";
import {API_URL, EPS} from "common/src/constants/Constants";
import {authHeader} from "../../helpers/auth-header";
import axios from 'axios';
import {Link} from "react-router-dom";
import {ActivityIndicator} from "react-native";

const Ficheros = props => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getData();
    }, [])

    function getData() {
        const endpoint = API_URL + EPS.CATEGORIAS;
        setLoading(true)

        const btd = {headers: {...authHeader()}};

        if (!btd.headers.Authorization) {
            setLoading(false);
            return;
        }

        const config = {
            headers: {Authorization: btd.headers.Authorization},
        };

        axios
          .get(endpoint, config)
          .then(d => {
              setData(d.data);
              setLoading(false);
          })
          .catch(e => {
              console.log(e);
              setLoading(false)
          });
    }


    return <CenteredLayout title={"Categorias de ficheros"}>
        <div className="row tiempoDetalle">
            <div className="col">
                <div style={{maxHeight: '84vh', overflowY: 'auto'}}>
                    {loading && <ActivityIndicator/>}

                    {data.length > 0 ? (
                      data.map((d, index) => <div
                          key={index}
                          style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                              paddingLeft: 20,
                              paddingRight: 20,
                              borderBottom: "1px solid",
                              borderBottomColor: '#ccc',
                              marginBottom: 18,
                              paddingBottom: 4
                          }}
                        >
                            <Link
                              style={{display: 'flex', justifyContent: 'center', borderBottom: 0, textDecoration: "none"}}
                              to={"/categoria/" + d.id + "/" + d.nombre}
                            >
                                <p style={{cursor: "pointer", color: 'rgb(33, 150, 243)'}}>{d.nombre}</p>
                            </Link>
                        </div>
                      )
                    ) : (
                      <p>No hay categorias para mostrar</p>
                    )}
                </div>
            </div>

        </div>
    </CenteredLayout>
}

export default Ficheros;
