import React, {useEffect, useState} from 'react';
import CenteredLayout from "../../layouts/CenteredLayout";
import {API_URL, EPS} from "common/src/constants/Constants";
import {authHeader} from "../../helpers/auth-header";
import axios from 'axios';
import moment from "moment";
import {ActivityIndicator} from "react-native";

const VacacionesLibres = props => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAll, setShowAll] = useState(false)

  function getData() {
    const endpoint = API_URL + EPS.LIBRES_VACACIONES + '?expand=motivo';

    const btd = { headers: {...authHeader()} };

    if (!btd.headers.Authorization) {
      setLoading(false);
      return;
    }

    const config = {
      headers: {Authorization: btd.headers.Authorization},
    };

    axios
      .get(endpoint, config)
      .then(d => {
        if (showAll)
          setData(d.data.data);
        else{
          const today = moment();

          const filteredElements = d.data.data.filter(item => {
            const dateItem = moment(item.fecha, 'DD-MM-YYYY');
            return dateItem.isAfter(today);
          });

          setData(filteredElements);
        }

        setLoading(false);
      })
      .catch(e => console.log(e));
  }

  useEffect(() => {
    getData();
  }, [showAll]);

  if (loading)
    return <CenteredLayout title={"Vacaciones / Días Libres"}>
      <ActivityIndicator />
    </CenteredLayout>



  return <CenteredLayout title={"Vacaciones / Días Libres"}>
    <div className="row tiempoDetalle">
      <div className="col">
        <div style={{maxHeight: '84vh', overflowX: 'auto'}}>
          {data.length === 0 && <p>No hay resultados para mostrar.</p>}

          {data.map((d, index) => <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                paddingLeft: 20,
                paddingRight: 20,
                borderBottom: "1px solid",
                borderBottomColor: '#ccc',
                marginBottom: 18,
                paddingBottom: 4
              }}
            >
              <p>{d.fecha} - {d.motivo.nombre}</p>
            </div>
          )}
        </div>
      </div>
    </div>

    <button onClick={() => setShowAll(!showAll)} className="btn btn-primary">{!showAll ? "Mostrar todos" : 'Mostrar pendientes'}</button>
  </CenteredLayout>
}

export default VacacionesLibres;
