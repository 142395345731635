import React from 'react';
import {
    Text,
    SectionList,
    View
} from 'react-native';
import PropTypes from 'prop-types';
import moment from 'moment';
import TiempoItem from "./TiempoItem";

export default function TiempoListar(props) {
    const { data } = props;
    const length = data.length;

    /**
     * Alert usar SectionList Necesito que sea un array de objetos con una propiedad con un array de los objetos de tiempos.
     * Quiero que el array tenga un formato:
     *
     * [
     *      {
     *           title: '23-12-2019',
     *           data: [objetoTiempo1, objetoTiempo2, ...]
     *      },
     *      {
     *           title: '22-12-2019',
     *           data: [objetoTiempo1, objetoTiempo2, ...]
     *      }
     * ]
     *
     * @param data
     * @returns {[]}
     */
    const parseData = data => {
        let result = [];

        for (let item of data) {
            const title = moment(item.inicio, 'DD-MM-YYYY hh:mm:ss').format('DD-MM-YYYY');
            const index = result.map(i => i.title).indexOf(title);

            let inicio = new moment(item.inicio, 'DD-MM-YYYY hh:mm:ss');
            let fechaInicio = '';
            if (inicio.isValid()) {
                fechaInicio = inicio.format('DD-MM-YYYY');
                inicio = inicio.format('HH:mm:ss');
            }
            else {
                inicio = '';
            }


            let fin = new moment(item.fin, 'DD-MM-YYYY hh:mm:ss');
            fin = fin.isValid() ? fin.format('HH:mm:ss') : '';

            const newItem = { inicio, fin, id: item.id, fechaInicio, usuario_id: item.usuario_id };

            if (index === -1) {
                result.push({
                    title,
                    data: [newItem]
                });
            }
            else {
                result[index].data.push(newItem);
            }
        }
        return result;
    }

    if (length === 0) return <Text>No hay tiempos que mostrar</Text>;

    const parsedData = parseData(data);

    // return data.map((item, index) => <SectionList><TiempoItem item={item} index={index} /></SectionList>)
    return (
        <div style={{maxHeight: '84vh', overflowY: 'auto'}}>
            <SectionList
                sections={parsedData}
                keyExtractor={(item, index) => item + index}
                renderItem={({item}) => <TiempoItem item={item} />}
                renderSectionHeader={ ({ section } ) =><>
                    <View style={{paddingLeft: 10, backgroundColor: '#f2f2f2'}}>
                        <Text style={{lineHeight: 40}}>{section.title}</Text>
                    </View>
                </>
                }
            />
        </div>
    )
}

TiempoListar.propTypes = {
    data: PropTypes.array.isRequired,
};

